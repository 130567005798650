import Vue from "vue"
import Router from "vue-router"
import { auth } from "../firebaseConfig"

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(error => {
    if (
      error.name != "NavigationDuplicated" &&
      !error.message.includes("Navigation cancelled")
    ) {
      throw error
    }
  })
}

Vue.use(Router)

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      redirect: { name: "Sales", params: { tab: "orders" } },
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("@/Login"),
    },
    {
      path: "/statuses",
      name: "Order Statuses",
      component: () => import("@/AuthenticatedContent/OrderStatusViewer"),
    },
    {
      path: "/menu/:tab?/:form?/:id?",
      name: "Menu",
      component: () => import("@/AuthenticatedContent/Menus"),
    },
    {
      path: "/modifiers/:tab?/:form?/:id?",
      name: "Modifiers",
      component: () => import("@/AuthenticatedContent/Menus/Modifiers"),
    },
    {
      path: "/onboarding/:step?/:form?/:id?",
      name: "Onboarding",
      component: () => import("@/AuthenticatedContent/Onboarding"),
    },
    {
      path: "/sales/:tab?/:form?/:id?",
      name: "Sales",
      component: () => import("@/AuthenticatedContent/Sales"),
    },
    {
      path: "/reporting/:tab/:id?",
      name: "Reporting",
      component: () => import("@/AuthenticatedContent/Reporting"),
    },
    {
      path: "/settings/:tab?/:form?/:id?",
      name: "Settings",
      component: () => import("@/AuthenticatedContent/Settings"),
    },
    {
      path: "/coupons/:form?/:id?",
      name: "Coupons",
      component: () => import("@/AuthenticatedContent/Marketing/Coupons"),
    },
    {
      path: "/promotions",
      name: "Promotions",
      component: () => import("@/AuthenticatedContent/Marketing/Promotions"),
    },
    {
      path: "/customers",
      name: "Customers",
      component: () => import("@/AuthenticatedContent/Marketing/Customers"),
    },
    {
      path: "/supplierItems/:tab/:form?/:id?",
      name: "Order Guide",
      component: () => import("@/AuthenticatedContent/SupplierItems"),
    },
    {
      path: "/inventory/:form?/:id?",
      name: "Inventory",
      component: () => import("@/AuthenticatedContent/Inventory"),
    },
    {
      path: "/receive/:tab?/:form?/:id?",
      name: "Receiving",
      component: () => import("@/AuthenticatedContent/Operations/Receive"),
    },
    {
      path: "/count/:tab?/:form?/:id?",
      name: "Count Inventory",
      component: () => import("@/AuthenticatedContent/Operations/Count"),
    },
    {
      path: "/writeoff/:tab?/:form?/:id?",
      name: "Write-off",
      component: () => import("@/AuthenticatedContent/Operations/Writeoffs"),
    },
    {
      path: "/purchase/:tab?/:id?",
      name: "Purchase Orders",
      component: () => import("@/AuthenticatedContent/Operations/PurchaseOrders"),
    },
    {
      path: "/varianceReport",
      name: "Variance Report",
      component: () => import("@/AuthenticatedContent/Inventory/VarianceReport"),
    },
    {
      path: "/logout",
      name: "Logout",
      component: () => import("@/Logout"),
    },
    { path: "*", name: "404", component: () => import("@/NotFound") },
  ],
  scrollBehavior() {
    return { y: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  if (to.name === "Logout") next()
  else {
    const isAuthenticated = await auth.getCurrentUser()
    if (to.name !== "Login" && !isAuthenticated)
      next({
        name: "Login",
        query: { redirect: to.path },
        meta: { hideNavbar: true },
      })
    else if (to.name === "Login" && isAuthenticated) {
      if (to.query.redirect) next({ path: to.query.redirect })
      else next({ name: "Home" })
    } else next()
  }
})

export default router
