<template>
  <div>Logging out...</div>
</template>

<script>
import { signOut } from "firebase/auth"
import { auth } from "./firebaseConfig"
import { mapActions, mapMutations } from "vuex"

export default {
  async mounted() {
    signOut(auth)
      .then(() => {
        this.setCurrentUserEmail(null)
        this.unsubscribeFromFirebaseSnapshots()
        this.$router.push({
          name: "Login",
          query: { loggedOut: "true", redirect: this.$route.query.redirect },
        })
      })
      .catch(error => {
        alert(error)
      })
  },
  methods: {
    ...mapMutations(["setCurrentUserEmail"]),
    ...mapActions(["unsubscribeFromFirebaseSnapshots"]),
  },
}
</script>
