<template>
  <div
    :style="`height: 100vh; opacity: ${
      slide.hide ? 0 : 1
    }; transition: opacity 1s ease-in-out; background: ${slide.backgroundColor};`"
  >
    <v-img
      v-if="!slide.isVideo"
      :contain="slide.containImage"
      :src="slide.imageUrl"
      style="height: 100vh"
    ></v-img>
    <video
      v-else
      playsinline
      autoplay
      muted
      :loop="false"
      :style="`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: ${slide.containImage ? 'contain' : 'cover'};
      `"
    >
      <source :src="slide.imageUrl" type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {
  name: "order-status-viewer-slide",
  props: {
    slide: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
