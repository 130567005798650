<template>
  <v-app style="background: rgba(125, 125, 125, 0.2) !important">
    <v-container class="mt-12">
      <v-card class="pb-6" :class="{ 'pt-2': !img }">
        <v-img
          v-if="img"
          :src="img"
          height="60vh"
          position="50% 50%"
          :contain="imgContain"
          style="background: #eee"
        ></v-img>
        <v-card-title class="mt-6 mx-4">
          <h2>{{ title }}</h2>
        </v-card-title>
        <v-card-text class="mx-4">
          <slot></slot>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "empty",
  props: {
    title: {
      type: String,
      default: "",
      required: false,
    },
    img: {
      type: String,
      default: "",
      required: false,
    },
    imgContain: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
}
</script>
