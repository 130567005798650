import EscPosEncoder from "@manhnd/esc-pos-encoder"
import { loadScript } from "vue-plugin-load-script"
import { mapState } from "vuex"
import moment from "moment"

export default {
  computed: {
    ...mapState(["settings", "customers"]),
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    printReceiptToPrinter(item) {
      const encoder = new EscPosEncoder()
      const data = encoder
        .initialize()
        .text("Hello, World!\n")
        .newline()
        .newline()
        .cut("partial")
        .encode()

      this.printerSocket = new WebSocket(`ws://${this.settings.printerIP}:9100/ws`)
      this.printerSocket.onopen = function () {
        // console.log("Websocket is open...")
        this.printerSocketOpen = true
        this.printerSocket.send(data)
        this.printerSocket.close()
      }
      this.printerSocket.onclose = function () {
        // console.log("Websocket is CLOSED...")
        this.printerSocketOpen = false
      }
      this.printerSocket.onmessage = function (evt) {
        // eslint-disable-next-line no-unused-vars
        var received_msg = evt.data
        // console.log("Message received...", received_msg)
      }
    },
    printReceiptToPrinterManual(item) {
      loadScript("/epos-2.23.0.js")
        .then(() => {
          // eslint-disable-next-line no-undef
          var builder = new epson.ePOSBuilder()
          builder.addTextAlign(builder.ALIGN_CENTER)
          builder.addTextLang("en")
          builder.addTextSmooth(true)
          builder.addTextSize(2, 2)
          builder.addTextFont(builder.FONT_C)
          builder.addText(this.settings.organizationName + "\n\n")
          builder.addTextSize(1, 1)
          if (this.settings.organizationAddress) {
            builder.addText(this.settings.organizationAddress + "\n")
          }
          if (this.settings.organizationPhoneNumber) {
            builder.addText(this.settings.organizationPhoneNumber + "\n")
          }
          if (this.settings.organizationWebsiteURL) {
            builder.addText(this.settings.organizationWebsiteURL + "\n")
          }
          builder.addText("\n")
          builder.addTextFont(builder.FONT_A)
          builder.addTextStyle(false, false, true, undefined)
          builder.addTextLineSpace(45)

          builder.addText("Order type: " + this.getOrderType(item))
          builder.addText(" Order via " + this.getOrderSource(item))
          builder.addText("\n" + this.getOrderDate(item.orderDate))
          builder.addText(
            "\nPickup time: " +
              this.getOrderDate(item.pickupDate) +
              " " +
              this.getOrderTime(item.pickupDate)
          )
          builder.addText("\n")
          if (item.orderNumber) {
            builder.addTextFont(builder.FONT_C)
            builder.addTextSize(2, 2)
            builder.addText("\n")
            builder.addText("Order #" + item.orderNumber)
            if (item.tableNumber) {
              builder.addText(" for " + item.tableNumber)
            }
            builder.addText("\n")
            builder.addTextFont(builder.FONT_A)
            builder.addTextSize(1, 1)
          }
          builder.addText("\n")
          // builder.addHLine()
          // builder.addText("------------------------------------------------\n")
          builder.addTextAlign(builder.ALIGN_LEFT)
          item.orderItems.forEach(orderItem => {
            builder.addText(
              this.getPrintLineWithAmount(
                orderItem.quantity + "x " + orderItem.name,
                orderItem.priceMoney.amount
              )
            )
            orderItem.modifiers.forEach(mod => {
              builder.addText(
                this.getPrintLineWithAmount("    " + mod.name, mod.priceMoney.amount)
              )
            })
          })
          builder.addText("\n")
          // builder.addHLine()
          builder.addText("------------------------------------------------")
          builder.addText(
            this.getPrintLineWithAmount("Subtotal: ", item.priceData.subTotal)
          )
          builder.addText(
            this.getPrintLineWithAmount("Discount: ", item.priceData.discount)
          )
          builder.addText(
            this.getPrintLineWithAmount("Tip:      ", item.priceData.tip)
          )
          // if (item.priceData.serviceCharge) {
          this.getPrintLineWithAmount(
            "Service Charge: ",
            item.priceData.serviceCharge
          )
          // }
          // if (item.priceData.deliveryCost) {
          this.getPrintLineWithAmount("Delivery Fee: ", item.priceData.deliveryCost)
          // }
          builder.addText(
            this.getPrintLineWithAmount(
              "Taxes:    ",
              item.priceData.totalTaxes || item.priceData.taxes
            )
          )
          builder.addText(
            this.getPrintLineWithAmount("Total:    ", item.priceData.total)
          )
          if (item.orderNote) {
            builder.addText("\nOrder note: \n" + item.orderNote)
          }
          builder.addText("\n\n")
          builder.addText("\n\n")
          builder.addText("Thank you for your order!\n")
          builder.addText("\n\n")
          builder.addText("------------------------------------------------")
          builder.addText("\n\n")
          // only add these lines if the value exists
          if (item.tableNumber) builder.addText("\nName: " + item.tableNumber)
          else if (this.getCustomerName(item.userId))
            builder.addText("\nName: " + this.getCustomerName(item.userId))
          if (item.channelData?.deliveryAddress?.source)
            builder.addText(
              "\nAddress: " + item.channelData?.deliveryAddress?.source
            )
          if (item.channelData?.deliveryAddress?.extraAddressInfo)
            builder.addText(
              " " + item.channelData?.deliveryAddress?.extraAddressInfo
            )
          if (item.deliveryNote)
            builder.addText("\n\nDelivery notes:" + item.deliveryNote)
          builder.addText("\n\n")

          builder.addCut(builder.CUT_FEED)
          var request = builder.toString()
          // eslint-disable-next-line no-console
          console.log("PRINTER REQUEST", request)
          var address =
            location.protocol +
            `//${this.settings.printerIP}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000`
          //Create an ePOS-Print object
          // eslint-disable-next-line no-undef
          var epos = new epson.ePOSPrint(address)

          epos.onreceive = function (res) {
            // Obtain the print result and error code
            var msg =
              "Print" +
              (res.success ? "Success" : "Failure") +
              "\nCode: " +
              res.code +
              "\nStatus: \n"
            // Obtain the printer status
            var asb = res.status
            if (asb & epos.ASB_NO_RESPONSE) {
              msg += " No printer response\n"
            }
            if (asb & epos.ASB_PRINT_SUCCESS) {
              msg += " Print complete\n"
            }
            if (asb & epos.ASB_DRAWER_KICK) {
              msg += ' Status of the drawer kick number 3 connector pin = "H"\n'
            }
            if (asb & epos.ASB_OFF_LINE) {
              msg += " Offline status\n"
            }
            if (asb & epos.ASB_COVER_OPEN) {
              msg += " Cover is open\n"
            }
            if (asb & epos.ASB_PAPER_FEED) {
              msg += " Paper feed switch is feeding paper\n"
            }
            if (asb & epos.ASB_WAIT_ON_LINE) {
              msg += " Waiting for online recovery\n"
            }
            if (asb & epos.ASB_PANEL_SWITCH) {
              msg += " Panel switch is ON\n"
            }
            if (asb & epos.ASB_MECHANICAL_ERR) {
              msg += " Mechanical error generated\n"
            }
            if (asb & epos.ASB_AUTOCUTTER_ERR) {
              msg += " Auto cutter error generated\n"
            }
            if (asb & epos.ASB_UNRECOVER_ERR) {
              msg += " Unrecoverable error generated\n"
            }
            if (asb & epos.ASB_AUTORECOVER_ERR) {
              msg += " Auto recovery error generated\n"
            }
            if (asb & epos.ASB_RECEIPT_NEAR_END) {
              msg += " No paper in the roll paper near end detector\n"
            }
            if (asb & epos.ASB_RECEIPT_END) {
              msg += " No paper in the roll paper end detector\n"
            }
            if (asb & epos.ASB_BUZZER) {
              msg += " Sounding the buzzer (limited model)\n"
            }
            if (asb & epos.ASB_SPOOLER_IS_STOPPED) {
              msg += " Stop the spooler\n"
            }
            // eslint-disable-next-line no-console
            console.log(msg)
          }

          // eslint-disable-next-line no-console
          console.log(epos)
          //Send the print document
          epos.send(request)
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log(e)
        })
    },
    printTestReceiptToPrinterManual() {
      loadScript("/epos-2.23.0.js")
        .then(() => {
          // eslint-disable-next-line no-undef
          var builder = new epson.ePOSBuilder()
          builder.addTextAlign(builder.ALIGN_CENTER)
          builder.addTextLang("en")
          builder.addTextSmooth(true)
          builder.addTextSize(2, 2)
          builder.addTextFont(builder.FONT_C)
          builder.addText("*** RESTAURANT MANAGER TEST PRINT ***")
          builder.addTextSize(3, 3)
          builder.addTextFont(builder.FONT_C)
          builder.addText("\n\nSUCCESSFUL\n\n")
          builder.addTextSize(1, 1)
          builder.addText(
            "Receipt printing is working. You should be able to print receipts normally.\n"
          )
          builder.addText("\n\n")
          builder.addText("\n\n")

          builder.addCut(builder.CUT_FEED)
          var request = builder.toString()
          // eslint-disable-next-line no-console
          console.log("PRINTER REQUEST", request)
          var address =
            location.protocol +
            `//${this.settings.printerIP}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000`
          //Create an ePOS-Print object
          // eslint-disable-next-line no-undef
          var epos = new epson.ePOSPrint(address)

          epos.onreceive = function (res) {
            // Obtain the print result and error code
            var msg =
              "Print" +
              (res.success ? "Success" : "Failure") +
              "\nCode: " +
              res.code +
              "\nStatus: \n"
            // Obtain the printer status
            var asb = res.status
            if (asb & epos.ASB_NO_RESPONSE) {
              msg += " No printer response\n"
            }
            if (asb & epos.ASB_PRINT_SUCCESS) {
              msg += " Print complete\n"
            }
            if (asb & epos.ASB_DRAWER_KICK) {
              msg += ' Status of the drawer kick number 3 connector pin = "H"\n'
            }
            if (asb & epos.ASB_OFF_LINE) {
              msg += " Offline status\n"
            }
            if (asb & epos.ASB_COVER_OPEN) {
              msg += " Cover is open\n"
            }
            if (asb & epos.ASB_PAPER_FEED) {
              msg += " Paper feed switch is feeding paper\n"
            }
            if (asb & epos.ASB_WAIT_ON_LINE) {
              msg += " Waiting for online recovery\n"
            }
            if (asb & epos.ASB_PANEL_SWITCH) {
              msg += " Panel switch is ON\n"
            }
            if (asb & epos.ASB_MECHANICAL_ERR) {
              msg += " Mechanical error generated\n"
            }
            if (asb & epos.ASB_AUTOCUTTER_ERR) {
              msg += " Auto cutter error generated\n"
            }
            if (asb & epos.ASB_UNRECOVER_ERR) {
              msg += " Unrecoverable error generated\n"
            }
            if (asb & epos.ASB_AUTORECOVER_ERR) {
              msg += " Auto recovery error generated\n"
            }
            if (asb & epos.ASB_RECEIPT_NEAR_END) {
              msg += " No paper in the roll paper near end detector\n"
            }
            if (asb & epos.ASB_RECEIPT_END) {
              msg += " No paper in the roll paper end detector\n"
            }
            if (asb & epos.ASB_BUZZER) {
              msg += " Sounding the buzzer (limited model)\n"
            }
            if (asb & epos.ASB_SPOOLER_IS_STOPPED) {
              msg += " Stop the spooler\n"
            }
            // eslint-disable-next-line no-console
            console.log(msg)
          }

          //Send the print document
          epos.send(request)
        })
        .catch(e => {
          console.error(e)
        })
    },
    getPrintLineWithAmount(line, amount) {
      amount = "$" + (amount / 100).toFixed(2)
      let lineLength = line.length
      let amountLength = amount.toString().length
      let spaceLength = 47 - lineLength - amountLength
      let space = ""
      for (let i = 0; i < spaceLength; i++) {
        space += " "
      }
      return "\n " + line + space + amount
    },
    getOrderDate(date) {
      return moment(date.toDate()).format("MMM DD, YYYY")
    },
    getOrderTime(date) {
      return moment(date.toDate()).format("h:mm A")
    },
    getCustomerName(userId) {
      if (userId === "Guest") return userId
      return this.customers?.find(customer => customer.uid == userId) != undefined
        ? this.customers.find(customer => customer.uid == userId).displayName
        : "N/A"
    },
    getOrderType(order) {
      if (order.channelData?.orderType) {
        return order.channelData.orderType
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, str => str.toUpperCase())
      } else {
        return "N/A"
      }
    },
    getOrderSource(order) {
      if (order.channelData?.appType) {
        return order.channelData.appType
      } else {
        return "N/A"
      }
    },
  },
}
