<template>
  <div>
    <slide v-if="!slide0.remove && activeSlide === 0" :slide="slide0" />
    <slide v-else-if="!slide1.remove" :slide="slide1" />
    <v-container
      :style="`height: 100vh; position:absolute !important; top: 0; left: 0; right: 0; opacity: ${
        slide0.hide && slide1.hide ? 1 : 0
      }; transition: opacity 1s ease-in-out;`"
      class="fill-height d-flex align-center justify-center mt-8 mb-4"
    >
      <v-row class="align-self-start">
        <v-col class="d-flex justify-center">
          <v-main class="fill-height">
            <div class="d-flex align-center">
              <h2 class="text-uppercase page-title mb-4 flex-grow-1">
                Pickup Orders
              </h2>
              <img
                :src="organizationLogo"
                alt="Organization Logo"
                style="width: 100px"
                class="ml-4 my-n10 py-5 text-right"
              />
            </div>
            <v-card
              class="my-8"
              elevation="0"
              :style="`border: solid 1px ${organizationColor}`"
              :min-height="
                getOrdersByStatus('In Progress').length > 18 ? '20vh' : '40vh'
              "
            >
              <v-toolbar :color="organizationColor" dark flat>
                <v-toolbar-title class="text-uppercase">
                  Ready For Pickup
                </v-toolbar-title>
              </v-toolbar>
              <v-container>
                <v-row v-if="readyForPickupOrdersByColumn.length">
                  <v-col
                    v-for="(
                      readyForPickupOrders, index
                    ) in readyForPickupOrdersByColumn"
                    :key="`column-${index}`"
                  >
                    <v-list two-line>
                      <template v-for="item in readyForPickupOrders">
                        <v-list-item :key="item.id">
                          <v-list-item-icon>
                            <v-chip color="green" class="px-2 ml-2" x-small></v-chip>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ getItemTitle(item) }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-col>
                </v-row>
                <v-list v-else two-line>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        No orders ready for pickup yet.
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-container>
            </v-card>
            <v-card
              class="my-4"
              elevation="0"
              style="border: solid 1px #959595"
              flat
              :min-height="
                getOrdersByStatus('Ready For Pickup').length > 18 ? '20vh' : '40vh'
              "
            >
              <v-toolbar color="#959595" dark flat>
                <v-toolbar-title class="text-uppercase">In Progress</v-toolbar-title>
              </v-toolbar>
              <v-container>
                <v-row v-if="inProgressOrdersByColumn.length">
                  <v-col
                    v-for="(inProgressOrders, index) in inProgressOrdersByColumn"
                    :key="`column-${index}`"
                  >
                    <v-list two-line>
                      <template v-for="item in inProgressOrders">
                        <v-list-item :key="item.id">
                          <v-list-item-icon>
                            <v-chip
                              color="#959595"
                              class="px-2 ml-2"
                              x-small
                            ></v-chip>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ getItemTitle(item) }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-col>
                </v-row>
                <v-list v-else two-line>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        No orders in progress.
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-container>
            </v-card>
          </v-main>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Slide from "./Slide.vue"
import { mapGetters, mapState, mapActions } from "vuex"
import moment from "moment"

export default {
  name: "order-status-viewer",
  components: {
    Slide,
  },
  data() {
    return {
      slide0: {
        imageUrl: "",
        isVideo: false,
        remove: true,
        hide: true,
      },
      slide1: {
        imageUrl: "",
        isVideo: false,
        remove: true,
        hide: true,
      },
      activeSlide: 0,
    }
  },
  computed: {
    ...mapState([
      "orders",
      "refunds",
      "customers",
      "restaurants",
      "promoImages",
      "settings",
    ]),
    ...mapGetters(["getRestaurantName"]),
    organizationLogo() {
      return this.settings?.organizationLogo
    },
    organizationColor() {
      return this.settings?.organizationColor
    },
    inProgressOrdersByColumn() {
      return this.getOrdersByStatusByColumn("In Progress", "asc")
    },
    readyForPickupOrdersByColumn() {
      return this.getOrdersByStatusByColumn("Ready For Pickup", "desc")
    },
    ordersExist() {
      return (
        this.inProgressOrdersByColumn.length ||
        this.readyForPickupOrdersByColumn.length
      )
    },
    minuteSplit() {
      const lastPromoImage = this.promoImages.reduce((prev, current) =>
        prev.startSec > current.startSec ? prev : current
      )
      const lastPromoImageSec =
        parseInt(lastPromoImage.startSec) + parseInt(lastPromoImage.duration) + 15000
      const minuteSplit = Math.ceil(lastPromoImageSec / 60000)
      return minuteSplit
    },
  },
  async mounted() {
    document.documentElement.classList.add("XL-font-size")
    if (!this.promoImages?.length) {
      await this.fetchPromoImages()
    }
    if (this.promoImages?.length) {
      // find the time at which the hour reaches a whole fraction of the minute to wait for (it must be at 0 seconds)
      const timeToWaitFor = new Date(
        moment().startOf("minute").toDate().getTime() +
          (this.minuteSplit - (new Date().getMinutes() % this.minuteSplit)) * 60000
      )
      // wait until that time to start the interval
      setTimeout(() => {
        this.changePromoFromTo(-1, 0)
      }, timeToWaitFor - new Date().getTime())
      // console.log("timeToWaitFor", timeToWaitFor)
    }
  },
  onDestroyed() {
    document.documentElement.classList.remove("XL-font-size")
  },
  methods: {
    ...mapActions(["fetchPromoImages"]),
    changePromoFromTo(indexFrom, indexTo) {
      // console.log("changePromoFromTo", indexFrom, indexTo)
      // show / hide the promo image and switch slide if needed
      if (indexFrom === -1) {
        // switch the promo image
        if (indexTo !== -1) {
          this[`slide${this.activeSlide}`] = {
            ...this.promoImages[indexTo],
            remove: false,
            hide: true,
          }
          this[`slide${this.activeSlide}`].hide = false
        }
      } else if (indexTo === -1) {
        this[`slide${this.activeSlide}`].hide = true
        setTimeout(() => {
          this[`slide${this.activeSlide}`].remove = true
        }, 1500)
      } else {
        // hide the current slide, then remove it
        const currentSlide = this.activeSlide
        // console.log("hide currentSlide", currentSlide)
        this[`slide${currentSlide}`].hide = true
        setTimeout(() => {
          // console.log("remove currentSlide", currentSlide)
          this[`slide${currentSlide}`].remove = true
        }, 1500)
        // show the next slide
        const nextSlide = this.activeSlide === 1 ? 0 : 1
        // console.log("show nextSlide", nextSlide)
        this[`slide${nextSlide}`] = {
          ...this.promoImages[indexTo],
          hide: true,
          remove: false,
        }
        this.activeSlide = nextSlide
        this[`slide${nextSlide}`].hide = false
      }

      // schedule the next promo image switch
      let timeToWaitFor
      if (indexTo === -1) {
        timeToWaitFor =
          moment().startOf("minute").toDate().getTime() -
          new Date().getTime() +
          (this.minuteSplit - (new Date().getMinutes() % this.minuteSplit)) * 60000
      } else {
        timeToWaitFor = parseInt(this.promoImages[indexTo].duration)
      }
      // } else if (indexTo === this.promoImages.length - 1) {
      //   timeToWaitFor = parseInt(this.promoImages[indexTo].duration)
      // } else {
      //   timeToWaitFor = parseInt(this.promoImages[indexTo + 1].startSec)
      // }
      setTimeout(() => {
        this.changePromoFromTo(
          indexTo,
          indexTo === this.promoImages.length - 1 ? -1 : indexTo + 1
        )
      }, timeToWaitFor)
      // console.log("timeToWaitFor", timeToWaitFor)
    },
    getOrdersByStatusByColumn(status, sortDirection = "asc") {
      const byColumn = []
      const orders = this.getOrdersByStatus(status, sortDirection)
      const numColumns = 2
      orders.forEach((order, index) => {
        const columnIndex = index % numColumns
        if (!byColumn[columnIndex]) {
          byColumn[columnIndex] = []
        }
        byColumn[columnIndex].push(order)
      })
      return byColumn
    },
    getOrdersByStatus(status, sortDirection = "asc") {
      if (status == "In Progress") {
        return this.getOrders(sortDirection).filter(
          order => order.status === "Accepted" || order.status === "In Progress"
        )
      } else
        return this.getOrders(sortDirection).filter(order => order.status === status)
    },
    getOrders(sortDirection = "asc") {
      let filteredOrders = this.orders

      filteredOrders = filteredOrders.filter(order => {
        const conditions = []
        conditions.push(order => !["Pending", "Failed"].includes(order.status))
        conditions.push(this.orderHasCustomerName(order))
        if (this.settings?.orderStatus?.kioskOnly) {
          conditions.push(order.channelData?.appType === "kiosk")
        }
        return conditions.every(condition => condition)
      })
      return filteredOrders
        .sort((a, b) => {
          return (
            (a.pickupDate > b.pickupDate ? 1 : -1) *
            (sortDirection === "asc" ? 1 : -1)
          )
        })
        .sort((a, b) => {
          const aCriteria = String(
            a.tableNumber || a.orderNumber || this.getCustomerName(a.userId)
          ).toLowerCase()
          const bCiteria = String(
            b.tableNumber || b.orderNumber || this.getCustomerName(b.userId)
          ).toLowerCase()
          return aCriteria > bCiteria ? 1 : -1
        })
    },
    getItemTitle(item) {
      const customerName = this.getCustomerName(item.userId)
      if (item.tableNumber) {
        return item.tableNumber.charAt(0).toUpperCase() + item.tableNumber.slice(1)
      } else if (this.isKioskOrder(item) || customerName === "N/A") {
        return "Order #" + item.orderNumber
      } else {
        return customerName
      }
    },
    orderHasCustomerName(item) {
      return (
        item.tableNumber ||
        (this.getCustomerName(item.userId) !== "N/A" && !this.isKioskOrder(item))
      )
    },
    getCustomerName(userId) {
      if (userId === "Guest") return userId
      return this.customers?.find(customer => customer.uid == userId) != undefined
        ? this.customers.find(customer => customer.uid == userId).displayName
        : "N/A"
    },
    isKioskOrder(order) {
      return (
        this.customers?.find(customer => customer.uid == order.userId)?.firstName ===
        "Kiosk"
      )
    },
  },
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Century+Gothic");

html.XL-font-size {
  font-size: 32px !important;
  * {
    font-family: "Century Gothic", sans-serif !important;
  }
  .v-application {
    .v-toolbar,
    .v-toolbar__title,
    .v-toolbar__content {
      height: 2.5rem !important;
      font-size: 1.5rem !important;
    }
    .v-toolbar__content {
      margin-top: 0.1rem !important;
    }
    .v-list-item__icon {
      margin: 1rem 20px 1rem 0 !important;
      > span {
        width: 0.6rem !important;
        height: 0.6rem !important;
        padding: 0 !important;
        border-radius: 100% !important;
      }
    }
  }
}
</style>
