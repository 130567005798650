// There is a weird bug caused by the 3rd parties that causes the input
// fields to lose focus as soon as the user types the first character.
// This is a hack to get this field to remain focused.

export default {
  data() {
    return {
      lastFocusedElement: undefined,
      alreadyFocused: false,
    }
  },
  methods: {
    fixInputBlur() {
      document.addEventListener("focusin", function (e) {
        if (
          e.target.tagName === "TEXTAREA" ||
          (e.target.tagName === "INPUT" && this.lastFocusedElement !== e.target)
        ) {
          this.lastFocusedElement = e.target
          this.alreadyFocused = false
        }
      })
      document.addEventListener("keypress", function (e) {
        if (
          (e.target.tagName === "TEXTAREA" || e.target.tagName === "INPUT") &&
          !this.alreadyFocused
        ) {
          setTimeout(() => {
            this.lastFocusedElement.focus()
            this.alreadyFocused = true
          }, 50)
        }
      })
    },
  },
}
