import moment from "moment"

export function isDateRangeCovered(addedRange, existingRanges) {
  let dateRanges = JSON.parse(JSON.stringify(existingRanges))
  dateRanges.push(addedRange)
  let newRanges = minimizeRanges(dateRanges)

  // Check if the ranges have the same length
  if (newRanges.length !== existingRanges.length) {
    return false
  }

  // Sort the date ranges in both ranges by start date
  newRanges = [...newRanges].sort(
    (a, b) => new Date(a.startDate) - new Date(b.startDate)
  )
  existingRanges = [...existingRanges].sort(
    (a, b) => new Date(a.startDate) - new Date(b.startDate)
  )

  // Compare the JSON representation of the sorted ranges
  return JSON.stringify(newRanges) === JSON.stringify(existingRanges)
}

export function minimizeRanges(dateRanges) {
  // Sort date ranges by start date
  dateRanges.sort((a, b) => moment(a.startDate).diff(moment(b.startDate)))

  const minimizedRanges = [dateRanges[0]]

  for (let i = 1; i < dateRanges.length; i++) {
    const currentRange = minimizedRanges[minimizedRanges.length - 1]
    const nextRange = dateRanges[i]

    // Check if the current and next ranges overlap or are adjacent
    const currentEndDate = moment(currentRange.endDate)
    const nextStartDate = moment(nextRange.startDate)

    if (currentEndDate.isSameOrAfter(nextStartDate.subtract(1, "day"))) {
      // Merge the ranges by extending the current range's end date
      currentRange.endDate = moment
        .max(currentEndDate, moment(nextRange.endDate))
        .toDate()
    } else {
      // Push the next range as it doesn't overlap with the current one
      minimizedRanges.push(nextRange)
    }
  }

  return minimizedRanges
}
