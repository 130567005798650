<template>
  <empty title="404..." :img="require('@/assets/ratatouille.webp')">
    <p class="pt-2 pb-6">Sorry, but the page you're looking for doesn't exist.</p>
    <v-btn :to="{ path: '/' }">Back to Homepage</v-btn>
  </empty>
</template>

<script>
import Empty from "./Empty.vue"

export default {
  name: "not-found",
  components: {
    Empty,
  },
}
</script>
