import colors from "vuetify/lib/util/colors"

const baseTheme = {
  light: {
    primary: "#000",
    text: "#333",
    lighttext: "#777",
    "light-background": colors.grey.lighten4,
    "light-border": colors.grey.lighten2,
    "text-on-primary-bg": "#fff",
    background: "#fff",
  },
  dark: {
    primary: "#555",
    secondary: "#222",
    text: "#fff",
    lighttext: "#bbb",
    "light-background": colors.grey.darken4,
    "light-border": colors.grey.darken2,
    "text-on-primary-bg": "#fff",
    background: "#000",
  },
}

const themes = {
  black: baseTheme,
  nextgen: {
    light: {
      primary: "#05408f",
      text: "#333",
      lighttext: "#666",
      "light-background": colors.grey.lighten4,
      "light-border": colors.grey.lighten2,
      "text-on-primary-bg": "#fff",
      background: "#fff",
    },
    dark: {
      ...baseTheme.dark,
      ...{
        primary: "#05408f",
        secondary: "#222",
        "light-background": "#0d294f",
        "light-border": "#254879",
      },
    },
  },
}

export default themes
