import Vue from "vue"
import Vuex from "vuex"
import VuexPersistence from "vuex-persist"

import * as actions from "./actions"
import * as getters from "./getters"
import * as mutations from "./mutations"

Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({ currentUserEmail: state.currentUserEmail }),
})

export const store = new Vuex.Store({
  state: {
    loadProgress: 0,
    loadText: "Loading...",
    notification: "",
    currentUserEmail: "",
    currentUserRole: "",
    currentUserAccess: {},
    onboardings: [],
    currentOnboardingIndex: 0,
    restaurantSyncStatus: {},
    restaurants: [],
    loyaltyRewards: [],
    menus: [],
    menuItems: [],
    menuItemCategories: [],
    filters: {},
    showDeleted: false,
    soundOn: false,
    settings: {},
    settingsByVisibility: {
      public: {},
      organization: {},
      private: {},
    },
    categories: [],
    subcategories: [],
    promoImages: [],
    thirdPartyTypes: [],
    users: [],
    allUsers: [],
    taxes: [],
    coupons: [],
    firebaseRefs: {},
    firebaseGetters: {},
    firebaseSnapshotUnsubscribeObjects: [],
    modifierLists: [],
    modifierItems: [],
    orders: [],
    paidOrderIds: [],
    discountedOrders: [],
    customerOrders: [],
    allOrders: [],
    allOpenOrders: [],
    allOrdersLastFetched: null,
    allOrdersDateRanges: [],
    ordersToPrint: [],
    refunds: [],
    customers: [],
    channels: [],
    itemToEdit: null,
    itemToReport: null,
    profitMargin: 0.3,
    reportDateRange: [new Date().toISOString().split("T")[0]],
    inventoryFetched: false,
    items: [],
    supplierItems: [],
    storages: [],
    orderGuides: [],
    suppliers: [],
    units: [],
    purchaseOrders: [],
    counts: [],
    writeoffs: [],
    receivings: [],
    cuisines: [],
  },
  plugins: [vuexLocal.plugin],
  mutations,
  actions,
  getters,
})

export default store
