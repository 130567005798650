import { initializeApp } from "firebase/app"
import "firebase/compat/firestore"
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth"
import { getStorage, ref } from "firebase/storage"
import { getFunctions } from "firebase/functions"

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}
const auth = getAuth(initializeApp(config))
const adminAuth = getAuth(initializeApp(config, "userAdmin")) // this is used for creating users
const db = getFirestore(initializeApp(config))
const storage = ref(getStorage())
const functions = getFunctions(initializeApp(config))

// setLogLevel("debug")

auth.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      unsubscribe()
      resolve(user)
    }, reject)
  })
}

export default db
export { db, auth, adminAuth, storage, functions }
