export function getRestaurants(state) {
  return state.restaurants
}
export function getSettings(state) {
  return state.settings
}
export function getCategories(state) {
  return state.categories
}
export function getSubcategories(state) {
  return state.subcategories
}
export function getSuppliers(state) {
  return state.suppliers
}
export function getStorages(state) {
  return state.storages
}
export function getThirdPartyTypes(state) {
  return state.thirdPartyTypes
}
export function getOrderGuides(state) {
  return state.orderGuides
}
export function getSupplierItems(state) {
  return state.supplierItems
}
export function getUnits(state) {
  return state.units
}
export function getMenuItemCategories(state) {
  return state.menuItemCategories
}

export function getItemToEdit(state) {
  return state.itemToEdit
}

export function getItemToReport(state) {
  return state.itemToReport
}

export function getProfitMargin(state) {
  return state.profitMargin
}

export function getCurrentUserEmail(state) {
  return state.currentUserEmail
}

export function currentOnboarding(state) {
  return state.onboardings[state.currentOnboardingIndex]
}

/*
@params
  state: allows the use of vuex variables
  modItemId: id of a modifier item
@return
  name of modifier item

Given the id of a modifier item return its name
*/
export function getModifierItemName(state) {
  return modItemId => {
    return state.modifierItems.find(modItem => modItem.id == modItemId).name
  }
}

/*
@params
  state: allows the use of vuex variables
  modListId: id of a modifier list
@return
  name of the modifier list

Given the id of a modifier list return its name
*/
export function getModifierListName(state) {
  return modListId => {
    const modifierList = state.modifierLists.find(modList => modList.id == modListId)
    return modifierList ? modifierList.name : "(Unknown modifier)"
  }
}

/*
@params
  state: allows the use of vuex variables
  restaurantId: the id of the restaurant who's name we want to retrieve
@return
  name of the restaurant with id restaurantId

Given the id of a restaurant return its name
(The implementation of this function considers the case of returning only one restaurant name, 
  if multiple names are required the function is to be calle as many times as needed)
*/
export function getRestaurantName(state) {
  return restaurantId => {
    if (restaurantId == null) return "N/A"
    const restaurant = state.restaurants.find(
      restaurant => restaurant.id == restaurantId
    )
    if (restaurant) return restaurant.name
    else return restaurantId
  }
}

export function getOrderGuideName(state) {
  return orderGuideId => {
    if (orderGuideId == null) return "N/A"
    const orderGuide = state.orderGuides.find(
      orderGuide => orderGuide.id == orderGuideId
    )
    if (orderGuide) return orderGuide.name
    else return "N/A"
  }
}

export function getUnitName(state) {
  return (unitId, plural, fullName) => {
    if (unitId == null) return "N/A"
    const unit = state.units.find(unit => unit.id == unitId)
    if (unit) {
      return unit.name + (fullName && plural ? "s" : "")
    } else return "N/A"
  }
}
export function getSupplierName(state) {
  return supplierId => {
    return state.suppliers.find(supplier => supplier.id == supplierId)?.name
  }
}

export function getStorageName(state) {
  return storageId => {
    const storage = state.storages.find(storage => storage.id == storageId)
    return storage?.name || "?"
  }
}

export function getRestaurantSyncStatus(state) {
  return restaurantId => {
    return state.restaurantSyncStatus
      ? state.restaurantSyncStatus[restaurantId]
      : true
  }
}

export function getFilteredOrdersFromSnapshot(state) {
  return ordersSnapshot =>
    ordersSnapshot.docs
      .map(doc => doc.data())
      .filter(thisOrder => {
        if (!thisOrder?.id || thisOrder.isDeleted) return false
        if (
          state.settings?.hideThirdPartyOrders === true &&
          !["kiosk", "web", "mobile"].includes(thisOrder.channelData?.appType)
        ) {
          return false
        }
        return true
      })
      .sort(function (a, b) {
        return new Date(a.orderDate) - new Date(b.orderDate)
      })
}

export function getReportDateRange(state) {
  return state.reportDateRange
}

export function siteTitle() {
  const isNextEats = window.location.hostname === "admin.nexteats.ca"
  return isNextEats ? "NextEats" : "NextGen Kitchens"
}

export function siteLogo() {
  const logoPath = siteTitle() === "NextEats" ? "logos/nexteats.webp" : "logo.svg"
  return require("@/assets/" + logoPath)
}

export function isNextGenUser(state) {
  return (
    state.currentUserRole === "Admin" &&
    state.currentUserEmail?.includes("nextgenkitchens.com")
  )
}

export function userCanEdit(state) {
  return !state.settings?.roleSettings?.[state.currentUserRole]?.disableAllEdit
}

export function isTouchDevice() {
  return window.matchMedia("(pointer: coarse)").matches
}

export function receiptPrinterEnabled(state) {
  return state.settings?.printerIP
}

export function getFilter(state) {
  return (page, filterName) => {
    return state.filters[page]?.[filterName]
  }
}
