<template>
  <span>
    <v-btn
      small
      class="mr-2"
      fab
      :color="color"
      rounded
      text
      :title="title"
      :disabled="disabled || loading"
      @click="click"
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
    >
      <v-progress-circular
        v-if="loading"
        indeterminate
        size="28"
        color="success"
        style="margin-right: -26px"
      ></v-progress-circular>
      <v-icon :class="{ 'mt-n2': type === 'refund' }">{{ icon }}</v-icon>
    </v-btn>
    <delete-dialog
      v-if="showDeleteDialog"
      :show="showDeleteDialog"
      :itemToDelete="item"
      :itemType="itemType"
      @close="showDeleteDialog = false"
      @deleted="$emit('deleted')"
    />
    <v-dialog
      v-model="showConfirmDialog"
      persistent
      max-width="700"
      @keydown.esc="showConfirmDialog = false"
    >
      <v-card>
        <v-card-title>
          <h5>
            {{
              typeof confirm == "boolean"
                ? "Are you sure you want to take this action?"
                : confirm
            }}
          </h5>
        </v-card-title>
        <v-card-actions class="justify-end">
          <v-btn color="secondary" @click="showConfirmDialog = false">Cancel</v-btn>
          <v-btn
            color="primary"
            @click="
              $emit('click')
              showConfirmDialog = false
            "
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import DeleteDialog from "./dialogs/DeleteDialog.vue"
export default {
  name: "nxg-action-btn",
  components: {
    DeleteDialog,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    itemType: {
      type: String,
      required: false,
      default: "",
    },
    item: {
      type: Object,
      required: false,
      default: () => {},
    },
    confirm: {
      type: [Boolean, String],
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showDeleteDialog: false,
      showConfirmDialog: false,
      hovered: false,
    }
  },
  computed: {
    color() {
      if (this.type === "delete" && this.hovered) return "error"
      return this.$vuetify.theme.dark ? "text" : "secondary"
    },
    icon() {
      switch (this.type) {
        case "edit":
          return "mdi-pencil"
        case "delete":
          return "mdi-trash-can-outline"
        case "complete":
          return "mdi-check-circle"
        case "report":
          return "mdi-chart-box"
        case "refund":
          return "mdi-cash-refund"
        case "print":
          return "mdi-printer-pos"
        case "receive":
          return "mdi-truck-delivery"
        default:
          return "mdi-" + this.type
      }
    },
    title() {
      switch (this.type) {
        case "report":
          return "View Report"
        case "refund":
          return "Refund Order"
        case "print":
          return "Print Receipt"
        case "complete":
          return "Mark as Completed"
        default:
          return this.type.charAt(0).toUpperCase() + this.type.slice(1)
      }
    },
  },
  methods: {
    click() {
      if (this.disabled) return
      if (this.type === "delete" && this.itemType && this.item) {
        this.showDeleteDialog = true
      } else if (this.confirm) {
        this.showConfirmDialog = true
      } else {
        this.$emit("click")
      }
    },
  },
}
</script>

<style></style>
