<template>
  <small v-if="receiptPrinterEnabled" class="pr-4 text--disabled">
    <span v-if="sendingToPrinter">
      <i class="fa fa-spinner fa-spin"></i>
      Sending to printer...
    </span>
    <a v-else class="text--disabled" @click="troubleShootPrinting">
      Printer Troubleshooting
    </a>
  </small>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import printTestReceiptToPrinterManual from "@/mixins/printReceipt.js"

export default {
  name: "printer-troubleshoot-button",
  mixins: [printTestReceiptToPrinterManual],
  data() {
    return {
      sendingToPrinter: false,
    }
  },
  computed: {
    ...mapGetters(["receiptPrinterEnabled"]),
    ...mapState(["settings"]),
  },
  methods: {
    troubleShootPrinting() {
      if (
        confirm(
          "Not able to print receipts on this page? Follow these troubleshooting steps:\n\n" +
            "1. Turn the printer off and on\n" +
            "2. Wait for the piece of paper to print" +
            " with the IP address on it\n" +
            "3. Press “OK” to send a TEST print to your printer."
        )
      ) {
        this.sendingToPrinter = true
        this.printTestReceiptToPrinterManual()
        setTimeout(() => {
          this.sendingToPrinter = false
          setTimeout(() => {
            if (
              confirm(
                "Check to see if the TEST receipt was printed.\n\n" +
                  "If it did NOT print, press OK. \n" +
                  '(Otherwise press "Cancel")'
              )
            ) {
              alert(
                "Ok, let's continue with the troubleshooting. Once you click OK below, a new tab will open. Follow these instructions:\n\n" +
                  "1. Once the tab opens, you might see a security warning. On this page, click “Advanced” then “Proceed anyways…”\n" +
                  "2. IGNORE the login that it asks you and just CLOSE that browser tab to come back to this page again."
              )
              window.open(`https://${this.settings.printerIP}`, "_blank")
              alert(
                "Try to print again. It should work now! If you are still having trouble, please reach out to support team."
              )
            } else {
              alert("Great! You're all set.")
            }
          }, 500)
        }, 2000)
      }
    },
  },
}
</script>
