<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="700"
    @keydown.esc="$emit('close')"
    @keydown.enter.prevent="confirmDelete"
  >
    <v-card>
      <v-card-title>
        <h5>Are you sure you want to delete this item?</h5>
      </v-card-title>
      <v-card-actions class="justify-end">
        <v-btn color="secondary" :disabled="submitting" @click="$emit('close')">
          Cancel
        </v-btn>
        <v-btn color="error" :disabled="submitting" @click="confirmDelete()">
          <v-progress-circular
            v-if="submitting"
            indeterminate
            size="15"
            width="2"
            class="mr-2"
          ></v-progress-circular>
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex"

export default {
  name: "delete-dialog",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    itemType: {
      type: String,
      required: true,
    },
    itemToDelete: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
    }
  },
  computed: {
    ...mapState(["firebaseRefs", "allUsers"]),
  },
  methods: {
    ...mapActions([
      "deleteMenuItem",
      "deleteMenu",
      "deleteMenuItemCategory",
      "deleteModifierItem",
      "deleteModifierList",
      "deleteTax",
      "deleteRestaurant",
      "updateUsers",
      "deleteStorage",
      "deleteOrderGuide",
      "deleteUnit",
      "deleteCoupon",
      "deletePromoImage",
      "deletePurchaseOrder",
      "deleteLoyaltyReward",
      "deleteThirdPartyType",
      "deleteInventoryItem",
      "deleteSupplierItem",
    ]),
    async confirmDelete() {
      this.submitting = true
      switch (this.itemType) {
        case "MENU_ITEM":
          this.deleteMenuItem(this.itemToDelete)
          break

        case "MENU":
          this.deleteMenu(this.itemToDelete)
          break

        case "MENU_ITEM_CATEGORY":
          this.deleteMenuItemCategory(this.itemToDelete)
          break

        case "MODIFIER_ITEM":
          this.deleteModifierItem(this.itemToDelete)
          break

        case "MODIFIER_LIST":
          this.deleteModifierList(this.itemToDelete)
          break

        case "TAX":
          this.deleteTax(this.itemToDelete)
          break

        case "RESTAURANT":
          this.deleteRestaurant(this.itemToDelete)
          break

        case "SUPPLIER_ITEM":
          this.deleteSupplierItem(this.itemToDelete)
          break

        case "PURCHASE_ORDER":
          this.deletePurchaseOrder(this.itemToDelete)
          break

        case "USER": {
          const updatedUsers = { ...this.allUsers }
          delete updatedUsers[this.itemToDelete.userId]
          this.updateUsers(updatedUsers)
          break
        }
        case "STORAGE":
          this.deleteStorage(this.itemToDelete)
          break
        case "ORDER_GUIDE":
          this.deleteOrderGuide(this.itemToDelete)
          break
        case "UNIT":
          this.deleteUnit(this.itemToDelete)
          break
        case "INVENTORY_ITEM":
          this.deleteInventoryItem(this.itemToDelete)
          break
        case "COUPON":
          await this.deleteCoupon(this.itemToDelete)
          break
        case "PROMO_IMAGE":
          await this.deletePromoImage(this.itemToDelete)
          break
        case "LOYALTY_REWARD":
          await this.deleteLoyaltyReward(this.itemToDelete)
          break
        case "THIRD_PARTY_TYPE":
          this.deleteThirdPartyType(this.itemToDelete)
          break
      }
      this.$emit("deleted")
      this.submitting = false
      this.$emit("close")
    },
  },
}
</script>
