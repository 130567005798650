<template>
  <v-snackbar v-model="showNotification" multi-line :timeout="timeout">
    <v-icon left>{{ notificationIcon }}</v-icon>
    {{ notificationMessage }}
    <template v-slot:action="{ attrs }">
      <v-btn color="pink" text v-bind="attrs" @click="hideNotification">Close</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import { useWebNotification } from "@vueuse/core"

export default {
  name: "notification-bar",
  computed: {
    ...mapState(["notification"]),
    notificationMessage() {
      return this.notification?.message
    },
    notificationIcon() {
      if (this.notification?.icon) {
        return "mdi-" + this.notification.icon
      } else return "mdi-information"
    },
    // calculate timeout based on length of notification message
    timeout() {
      if (
        !this.notification?.message?.length ||
        this.notification.message.length < 20
      ) {
        return 4000
      } else return this.notification.message.length * 100
    },
    showNotification: {
      get() {
        return (
          !!this.notification?.message && this.notification?.browserOnly !== true
        )
      },
      set(value) {
        if (!value) {
          this.setNotification(null)
        }
      },
    },
  },
  watch: {
    notification: {
      handler(val) {
        if (val?.message) {
          if (document.hidden || val.forceBrowser) {
            const { isSupported, show } = useWebNotification({
              title: val.message,
              dir: "auto",
              lang: "en",
              renotify: true,
              tag: "general",
            })
            if (isSupported.value) show()
          }
          if (val.browserOnly !== true) {
            var audio = new Audio(require("@/assets/notification.mp3"))
            audio.play()
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations(["setNotification"]),
    hideNotification() {
      this.showNotification = false
      this.$emit("close")
    },
  },
}
</script>
