var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(`height: 100vh; opacity: ${
    _vm.slide.hide ? 0 : 1
  }; transition: opacity 1s ease-in-out; background: ${_vm.slide.backgroundColor};`)},[(!_vm.slide.isVideo)?_c('v-img',{staticStyle:{"height":"100vh"},attrs:{"contain":_vm.slide.containImage,"src":_vm.slide.imageUrl}}):_c('video',{style:(`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: ${_vm.slide.containImage ? 'contain' : 'cover'};
    `),attrs:{"playsinline":"","autoplay":"","muted":"","loop":false},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.slide.imageUrl,"type":"video/mp4"}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }