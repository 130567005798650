var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"min-height":"100vh"}},[(_vm.docsLoaded)?_c('div',{staticClass:"fill-height"},[(_vm.$route.name === 'Order Statuses')?_c('order-status-viewer'):_c('div',{staticClass:"fill-height"},[_c('v-app-bar',{staticStyle:{"z-index":"1000"},attrs:{"app":"","clipped-left":"","dark":true}},[(_vm.currentUserRole !== 'Rider')?_c('v-app-bar-nav-icon',{staticClass:"d-lg-none mr-2",on:{"click":function($event){_vm.drawer = !_vm.drawer}}}):_vm._e(),_c('img',{staticClass:"mr-4",style:(_vm.getLogoStyle()),attrs:{"src":_vm.getLogoImg()}}),_c('v-app-bar-title',{staticClass:"text-no-wrap pl-0 d-none d-sm-block"},[_c('h1',{staticClass:"text-h6 pl-3",staticStyle:{"border-left":"solid 1px"}},[_vm._v(" Restaurant Manager "),(
                _vm.firebaseRefs.organization.env &&
                _vm.firebaseRefs.organization.env !== 'prod'
              )?_c('v-chip',{staticClass:"text-uppercase ml-1",attrs:{"color":"warning","text-color":"black"}},[_vm._v(" "+_vm._s(_vm.firebaseRefs.organization.env)+" ")]):(_vm.isLocalhost)?_c('v-chip',{staticClass:"text-uppercase ml-1",attrs:{"color":"success"}},[_vm._v(" LIVE ")]):_vm._e()],1)]),_c('v-spacer'),(_vm.currentUserRole !== 'Rider')?_c('v-btn',{staticClass:"mr-3",attrs:{"to":"/settings/settings","fab":"","rounded":"","small":"","color":"text-on-primary-bg"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-cog")])],1):_vm._e(),_c('v-menu',{staticStyle:{"z-index":"200"},attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","rounded":"","small":"","color":"text-on-primary-bg"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account")])],1)]}}],null,false,1855570712)},[_c('v-card',{staticClass:"mx-auto mt-2",attrs:{"tile":"","width":Math.min(
                Math.max(
                  (_vm.userDisplayName.length + _vm.settings.organizationName.length) * 10,
                  200
                ),
                300
              )}},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-subheader',[_c('span',{staticClass:"add-ellipsis"},[_vm._v(" "+_vm._s(_vm.settings.organizationName.toUpperCase())+" - "+_vm._s(_vm.userDisplayName.toUpperCase())+" ")])]),_c('v-divider'),_c('v-list-item',[_c('v-list-item-title',[_vm._v("DARK THEME")]),_c('v-switch',{staticClass:"align-self-start",attrs:{"color":_vm.$vuetify.theme.dark ? `success` : `light`},model:{value:(_vm.$vuetify.theme.dark),callback:function ($$v) {_vm.$set(_vm.$vuetify.theme, "dark", $$v)},expression:"$vuetify.theme.dark"}})],1),(_vm.currentUserEmail?.includes('nextgenkitchens.com'))?_c('v-list-item',{staticClass:"mt-n6"},[_c('v-list-item-title',[_vm._v("SHOW DELETED")]),_c('v-switch',{staticClass:"align-self-start",attrs:{"color":_vm.showDeleted ? `success` : `light`},model:{value:(_vm.showDeleted),callback:function ($$v) {_vm.showDeleted=$$v},expression:"showDeleted"}})],1):_vm._e(),_c('v-list-item',{staticClass:"mt-n6"},[_c('v-list-item-title',[_vm._v("SOUND ON")]),_c('v-switch',{staticClass:"align-self-start",attrs:{"color":_vm.soundOn ? `success` : `light`},model:{value:(_vm.soundOn),callback:function ($$v) {_vm.soundOn=$$v},expression:"soundOn"}})],1),_c('v-list-item',[_c('v-btn',{attrs:{"block":""},on:{"click":function($event){return _vm.$router.push({
                      name: 'Logout',
                      query: { redirect: _vm.$route.path },
                    })}}},[_c('span',{staticClass:"d-none d-md-flex"},[_vm._v("Sign Out")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-location-exit")])],1)],1)],1)],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loaded),expression:"loaded"}]},[(_vm.currentUserRole !== 'Rider')?_c('side-nav',{attrs:{"drawer":_vm.drawer}}):_vm._e(),_c('v-main',{staticClass:"fill-height"},[(!_vm.isOnline)?_c('v-alert',{staticClass:"ma-4",attrs:{"type":"warning"}},[_vm._v(" You are offline. Please connect to the internet. ")]):_vm._e(),_c('v-container',{staticClass:"px-4 py-0 pt-4 fill-height align-start main-container",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('transition',{attrs:{"name":"fade"}},[_c('router-view')],1)],1)],1)],1),_c('v-footer',{staticClass:"text--disabled",attrs:{"color":"light-background","bottom":"","padless":""}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('small',[_vm._v(" © "+_vm._s(new Date().getFullYear())+" "),_c('a',{staticStyle:{"color":"inherit","text-decoration":"none"},attrs:{"href":"https://nextgenkitchens.com/","target":"_blank"}},[_vm._v(" NextGen Kitchens ")])])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"6"}},[(
                  _vm.$route.name === 'Sales' &&
                  _vm.$route.params &&
                  _vm.$route.params.tab === 'orders'
                )?_c('printer-troubleshoot-button'):_vm._e()],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"3"}},[_c('small',[_c('a',{staticStyle:{"color":"inherit","text-decoration":"none"},attrs:{"href":"https://nextgenkitchens.com/terms-of-use/","target":"_blank"}},[_vm._v(" Terms of Use ")])]),_c('small',[_vm._v(" | ")]),_c('small',[_c('a',{staticStyle:{"color":"inherit","text-decoration":"none"},attrs:{"href":"https://nextgenkitchens.com/app-privacy-policy/","target":"_blank"}},[_vm._v(" Privacy Policy ")])])])],1)],1)],1),(!_vm.loaded)?_c('loading-page',[_vm._v("Loading...")]):_vm._e()],1)],1):(!_vm.isOnline)?_c('empty',{attrs:{"title":"No internet connection","img":_vm.getLogoImg(),"img-contain":""}},[_vm._v(" You are offline. Please connect to the internet. ")]):_c('loading-page',[_vm._v(_vm._s(_vm.loadText))]),_c('notification-bar')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }